export const LIST_OF_COUNTRY = [
    {
        Country: 'Choose your country',
        ISO: '1',
        Price: 0,
        noIcon: true,
        value: '1',
        default: true
    },
    {
        Country: 'Input your data',
        ISO: '0.0',
        Price: 0.0,
        noIcon: true,
        value: '0'
    },
    {
        Country: 'Average EU',
        ISO: 'eu',
        Price: 0.2,
        noIcon: true,
        value: 'eu'
    },
    {
        Country: 'Average MENA',
        ISO: 'MENA',
        Price: 0.08,
        noIcon: true,
        value: 'MENA'
    },
    {
        Country: 'Albania',
        ISO: 'al',
        Price: 0.11,
        value: 'al'
    },
    {
        Country: 'Algeria',
        ISO: 'dz',
        Price: 0.03,
        value: 'dz'
    },
    {
        Country: 'Austria',
        ISO: 'at',
        Price: 0.26,
        value: 'at'
    },
    {
        Country: 'Bahrain',
        ISO: 'bh',
        Price: 0.07,
        value: 'bh'
    },
    {
        Country: 'Belgium',
        ISO: 'be',
        Price: 0.23,
        value: 'be'
    },
    {
        Country: 'Bosnia and Herzegovina',
        ISO: 'ba',
        Price: 0.09,
        value: 'ba'
    },
    {
        Country: 'Bulgaria',
        ISO: 'bg',
        Price: 0.15,
        value: 'bg'
    },
    {
        Country: 'Croatia',
        ISO: 'hr',
        Price: 0.29,
        value: 'hr'
    },
    {
        Country: 'Cyprus',
        ISO: 'cy',
        Price: 0.27,
        value: 'cy'
    },
    {
        Country: 'Czech Republic',
        ISO: 'cz',
        Price: 0.2,
        value: 'cz'
    },
    {
        Country: 'Denmark',
        ISO: 'dk',
        Price: 0.13,
        value: 'dk'
    },
    {
        Country: 'Egypt',
        ISO: 'eg',
        Price: 0.03,
        value: 'eg'
    },
    {
        Country: 'Estonia',
        ISO: 'ee',
        Price: 0.16,
        value: 'ee'
    },
    {
        Country: 'Finland',
        ISO: 'fi',
        Price: 0.1,
        value: 'fi'
    },
    {
        Country: 'France',
        ISO: 'fr',
        Price: 0.25,
        value: 'fr'
    },
    {
        Country: 'Georgia',
        ISO: 'ge',
        Price: 0.09,
        value: 'ge'
    },
    {
        Country: 'Germany',
        ISO: 'de',
        Price: 0.22,
        value: 'de'
    },
    {
        Country: 'Greece',
        ISO: 'gr',
        Price: 0.21,
        value: 'gr'
    },
    {
        Country: 'Hungary',
        ISO: 'hu',
        Price: 0.3,
        value: 'hu'
    },
    {
        Country: 'Iceland',
        ISO: 'is',
        Price: 0.07,
        value: 'is'
    },
    {
        Country: 'Ireland',
        ISO: 'ie',
        Price: 0.28,
        value: 'ie'
    },
    {
        Country: 'Israel',
        ISO: 'il',
        Price: 0.14,
        value: 'il'
    },
    {
        Country: 'Italy',
        ISO: 'it',
        Price: 0.24,
        value: 'it'
    },
    {
        Country: 'Jordan',
        ISO: 'jo',
        Price: 0.09,
        value: 'jo'
    },
    {
        Country: 'Kosovo',
        ISO: 'xk',
        Price: 0.07,
        value: 'xk'
    },
    {
        Country: 'Kuwait',
        ISO: 'kw',
        Price: 0.05,
        value: 'kw'
    },
    {
        Country: 'Latvia',
        ISO: 'lv',
        Price: 0.15,
        value: 'lv'
    },
    {
        Country: 'Liechtenstein',
        ISO: 'li',
        Price: 0.32,
        value: 'li'
    },
    {
        Country: 'Lithuania',
        ISO: 'lt',
        Price: 0.17,
        value: 'lt'
    },
    {
        Country: 'Luxembourg',
        ISO: 'lu',
        Price: 0.25,
        value: 'lu'
    },
    {
        Country: 'Macedonia',
        ISO: 'mk',
        Price: 0.17,
        value: 'mk'
    },
    {
        Country: 'Malta',
        ISO: 'mt',
        Price: 0.13,
        value: 'mt'
    },
    {
        Country: 'Moldova',
        ISO: 'md',
        Price: 0.14,
        value: 'md'
    },
    {
        Country: 'Montenegro',
        ISO: 'me',
        Price: 0.09,
        value: 'me'
    },
    {
        Country: 'Morocco',
        ISO: 'ma',
        Price: 0.1,
        value: 'ma'
    },
    {
        Country: 'Netherlands',
        ISO: 'nl',
        Price: 0.23,
        value: 'nl'
    },
    {
        Country: 'Norway',
        ISO: 'no',
        Price: 0.11,
        value: 'no'
    },
    {
        Country: 'Oman',
        ISO: 'om',
        Price: 0.21,
        value: 'om'
    },
    {
        Country: 'Poland',
        ISO: 'pl',
        Price: 0.21,
        value: 'pl'
    },
    {
        Country: 'Portugal',
        ISO: 'pt',
        Price: 0.1,
        value: 'pt'
    },
    {
        Country: 'Qatar',
        ISO: 'qa',
        Price: 0.03,
        value: 'qa'
    },
    {
        Country: 'Romania',
        ISO: 'ro',
        Price: 0.33,
        value: 'ro'
    },
    {
        Country: 'Saudi Arabia',
        ISO: 'sa',
        Price: 0.06,
        value: 'sa'
    },
    {
        Country: 'Serbia',
        ISO: 'sr',
        Price: 0.14,
        value: 'sr'
    },
    {
        Country: 'Slovakia',
        ISO: 'sk',
        Price: 0.27,
        value: 'sk'
    },
    {
        Country: 'Slovenia',
        ISO: 'si',
        Price: 0.22,
        value: 'si'
    },
    {
        Country: 'Spain',
        ISO: 'es',
        Price: 0.12,
        value: 'es'
    },
    {
        Country: 'Sweden',
        ISO: 'se',
        Price: 0.11,
        value: 'se'
    },
    {
        Country: 'Switzerland',
        ISO: 'ch',
        Price: 0.24,
        value: 'ch'
    },
    {
        Country: 'Tunisia',
        ISO: 'tn',
        Price: 0.11,
        value: 'tn'
    },
    {
        Country: 'Turvalue',
        ISO: 'tr',
        Price: 0.13,
        value: 'tr'
    },
    {
        Country: 'United Arab Emirates',
        ISO: 'ae',
        Price: 0.1,
        value: 'ae'
    },
    {
        Country: 'Ukraine',
        ISO: 'ua',
        Price: 0.24,
        value: 'ua'
    },
    {
        Country: 'United Kingdom',
        ISO: 'gb',
        Price: 0.38,
        value: 'gb'
    }
];
