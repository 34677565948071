<template>
    <DashBoard />
</template>

<script>
import DashBoard from '@/components/DashBoard.vue';

export default {
    name: 'App',
    components: {
        DashBoard
    }
};
</script>

<style>
#app {
}
</style>
