export const languageOptions = [
    { value: 'en', label: 'EN' },
    { value: 'uk', label: 'UA' },
    { value: 'pl', label: 'PL' }
];

export const currencyOptions = [
    { value: 'EUR', label: 'EUR', symbol: '€' },
    { value: 'USD', label: 'USD', symbol: '$' },
    { value: 'UAH', label: 'UAH', symbol: '₴' },
    { value: 'PLN', label: 'PLN', symbol: 'zł' }
];
