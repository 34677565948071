<template>
    <div class="wrapper overflow-hidden">
        <header class="header" id="header">
            <div class="container flex flex-col gap-x-16">
                <div class="container__main-logo mb-2">
                    <img width="400" src="../assets/images/logo.png" alt="Modern Expo" class="logo" />
                    <span class="container__main-title uppercase">{{ $t('common.title') }}</span>
                    <div class="actions-section flex flex-col">
                        <div class="flex flex-col actions-section__selectors gap-3 items-end">
                            <div class="text-4xl info-button" @click="showInfoPopup">i</div>
                            <CustomSelect size="sm" :options="languageOptions" :selectedValue="selectedLanguage" @update:selectedOption="changeLanguage" />

                            <CustomSelect size="sm" :options="currencyOptions" :selectedValue="selectedCurrency.value" @update:selectedOption="changeCurrency" />
                        </div>
                    </div>
                </div>
                <div class="container__text-section flex flex-col gap-1">
                    <p class="container__text-section-description font-medium">
                        <span v-if="isSmallScreen">{{ $t('header.mobileDesc') }}</span>
                        <span v-else>{{ $t('header.description') }}</span>
                    </p>
                </div>
            </div>

            <InformationWrapper v-if="isShowPopup" @close="showInfoPopup" />
        </header>
        <CustomizationBlock :currency="selectedCurrency" v-if="!isShowPopup" />
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import InformationWrapper from '@/components/InformationWrapper.vue';
import { currencyOptions, languageOptions } from '@/components/const/select-options';
import CustomizationBlock from '@/components/CustomizationBlock.vue';
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue';

const { locale } = useI18n();
const isShowPopup = ref(false);

const selectedLanguage = ref('en');
const isSmallScreen = ref(window.innerWidth <= 800);
const updateScreenSize = () => {
    isSmallScreen.value = window.innerWidth <= 800;
};
const CURRENCY_OPTIONS = currencyOptions;
const selectedCurrency = ref(currencyOptions[0] || { value: 'EUR', label: 'EUR', symbol: '€' });

const changeLanguage = event => {
    const newLang = event.value;
    locale.value = newLang;
    selectedLanguage.value = newLang;
    localStorage.setItem('selectedLanguage', newLang);
};

const changeCurrency = currency => {
    selectedCurrency.value = findCurrency(currency.value);
    localStorage.setItem('selectedCurrency', JSON.stringify(currency.value));
};

const findCurrency = value => {
    return CURRENCY_OPTIONS.find(el => el.value === value);
};

const showInfoPopup = () => {
    isShowPopup.value = !isShowPopup.value;
};

onMounted(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    locale.value = savedLanguage;
    selectedLanguage.value = savedLanguage;
    const savedCurrency = localStorage.getItem('selectedCurrency');
    if (savedCurrency) {
        selectedCurrency.value = findCurrency(JSON.parse(savedCurrency)) || currencyOptions[0];
    } else {
        selectedCurrency.value = currencyOptions[0];
    }
    window.addEventListener('resize', updateScreenSize);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScreenSize);
});
</script>

<style lang="scss" scoped>
:host {
    color: var(--text-dark);
}

header {
    padding-top: 15px;
    padding-bottom: 20px;
}

.filter-option-inner-inner .flag-icon {
    padding-left: 65px;
}

.info-button {
    font-family: 'Roboto Slab', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid #000;
    color: #000;
    background-color: #c6cacb;
    cursor: pointer;
    font-size: 48px;
    font-weight: 400;
    line-height: 63.3px;
    text-align: left;

    &:hover {
        background: #000000;
        color: #f5f5f5;
        border: 2px solid #000000;
    }
}

.container {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;

    &__main {
        display: flex;

        &-logo {
            position: relative;
        }

        &-title {
            font-size: 70px;
            font-weight: 800;
            line-height: 82px;
            text-align: left;
        }
    }

    &__text-section-description {
        max-width: 80%;
        font-size: 17px;
        font-weight: 500;
        line-height: 19.92px;
    }

    .actions-section {
        position: absolute;
        right: 0;
        top: 0;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    header {
        height: 200px;
        padding: 0;
    }
    .container {
        max-width: 100%;
        padding: 32px 23px 17px 30px;
        &__main {
            display: flex;
            justify-content: unset;

            &-logo {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 330px;
                    height: 40px;
                }
            }

            &-title {
                font-size: 60px;
                line-height: 70.31px;
            }
        }

        &__text-section-description {
            max-width: 100%;
            font-size: 18px;
            font-weight: 500;
            line-height: 21.09px;
            text-align: left;
        }
    }

    .actions-section {
        position: unset !important;
        right: unset !important;

        flex-direction: row;

        &__selectors {
            flex-direction: row;
        }

        .info-button {
            order: 3;
        }
    }
}

@media (min-width: 1920px) {
    header {
        padding-top: 25px;
        padding-bottom: 15px;
        height: 200px;
    }
    .container {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 32px;

        &__main {
            &-logo {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
            }

            &-title {
                font-size: 80px;
            }
        }

        &__text-section-description {
            font-size: 22px;
            max-width: 100%;
        }
    }

    .actions-section {
        position: unset !important;
        right: unset !important;
        flex-direction: row;

        &__selectors {
            flex-direction: row;
            align-items: flex-start;
        }

        .info-button {
            order: 3;
        }
    }
}

@media (max-width: 800px) {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../assets/images/img/smal_arrow.png') var(--background-color) no-repeat 96% !important;
        font-weight: 500;
        line-height: 18.75px;
        text-align: left;
        padding: 5px;
    }
    .header {
        padding-top: 48px;
        padding-bottom: 8px;
    }
    .container {
        font-size: 12px;
        padding-left: 12px;
        padding-right: 12px;
        max-width: 100%;

        &__main {
            &-logo {
                display: flex;
                flex-direction: column;
                gap: 10px;

                img {
                    width: 164px;
                    height: 20px;
                }
            }

            &-title {
                font-size: 27px;
                font-weight: 800;
                line-height: 32.81px;
                text-align: left;
            }
        }

        &__text-section-description {
            font-weight: 500;
            text-align: left;
            font-size: 10px;
            line-height: 11.72px;
        }

        .info-button {
            width: 31px;
            height: 31px;
            font-size: 16px;
        }
    }

    .actions-section {
        top: -15px !important;
        &__selectors {
            gap: 10px;
        }
    }
}
</style>
