<template>
    <div ref="selectContainer" class="custom-select-container" :style="[styles]" :class="size" @click="toggleDropdown">
        <div class="selected-option">
            <slot :name="'customSelectedTemplate'">
                {{ selectedOption.label || placeholder }}
            </slot>
        </div>
        <div v-show="isOpen" class="options-list" :class="{ 'add-border': addBorder }">
            <div
                v-for="option in options"
                :key="option.value"
                @click.stop="selectOption(option)"
                :class="['custom-select__option', { selected: selectedOption.value === option.value }]">
                <slot :name="'optionContent'" :option="option">
                    {{ option.label }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeUnmount, watch } from 'vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    options: Array,
    selectedValue: Object,
    placeholder: String,
    styles: Object,
    size: {
        type: String,
        default: 'md',
        validator: value => ['sm', 'md', 'lg'].includes(value)
    },
    addBorder: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:selectedOption']);

const selectContainer = ref(null);
const isOpen = ref(false);
const selectedOption = ref({});

const handleClickOutside = event => {
    if (selectContainer.value && !selectContainer.value.contains(event.target)) {
        isOpen.value = false;
    }
};

watch(
    () => props.selectedValue,
    newValue => {
        selectedOption.value = props.options.find(option => option.value === newValue) || {};
    },
    { immediate: true }
);

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
};

const selectOption = option => {
    selectedOption.value = option;
    isOpen.value = false;
    emit('update:selectedOption', option);
};
</script>

<style scoped>
.sm {
    width: 120px;
    height: 68px;
}

.md {
    width: 166px;
    height: 88px;
}

.lg {
    width: 715px;
    height: 88px;
}

.custom-select-container {
    position: relative;
    cursor: pointer;
    background-color: var(--background-color);
    border: 2px solid black;
    border-radius: 6px;
    padding: 5px;
}

.selected-option {
    padding: 8px;
    background: url('../../assets/images/img/arrow.svg') var(--background-color) no-repeat 96%;
    font-size: 32px;
    font-weight: 500;
    line-height: 37.5px;
    text-align: left;
    align-items: center;
    height: 100%;
    display: flex;
}

.add-border {
    .custom-select__option {
        &:first-child,
        &:nth-child(4) {
            border-bottom: 2px solid #000000;
        }
    }
}

.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #c6cacb;
    border: 1px solid #999;
    border-radius: 6px;
    overflow-y: auto;
    z-index: 10;
    font-size: 32px;
    font-weight: 500;
    line-height: 37.5px;
    text-align: left;
    max-height: 400px;
}

.custom-select__option {
    padding: 8px;
    cursor: pointer;

    &:hover {
        background-color: #9c9c9c;
    }
}

.selected {
    background-color: #9c9c9c;
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .sm {
        width: 120px;
        height: 63px;
    }

    .md {
        width: 160px;
        height: 83px;
    }

    .lg {
        width: 400px;
        height: 83px;
    }

    .selected-option {
        padding: 8px;
        font-size: 32px;
    }

    .options-list {
        font-size: 30px;
    }

    .custom-select__option {
        padding: 8px;
    }
}

@media (min-width: 1920px) {
    .sm {
        width: 120px;
        height: 67px;
    }

    .md {
        width: 190px;
        height: 83px;
    }

    .lg {
        width: 595px;
        height: 83px;
    }

    .selected-option {
        background: url('../../assets/images/img/smal_arrow.png') var(--background-color) no-repeat 96%;

        padding: 8px;
        line-height: 33px;
    }

    .options-list {
        line-height: 33px;
    }

    .custom-select__option {
        padding: 8px;
    }
}

@media (max-width: 800px) {
    .sm {
        width: 52px;
        height: 31px;
    }

    .md {
        width: 60px;
        height: 28px;
    }

    .lg {
        width: 218px;
        height: 28px;
    }

    .selected-option {
        padding: 0;
        background-size: 10px 10px;

        font-size: 13px;
        font-weight: 500;
        text-align: left;
    }

    .options-list {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }

    .custom-select__option {
        padding: 8px;
    }
}
</style>
