import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import './assets/styles/app.scss';
import '/css/flag-icon.min.css';

import en from './locales/en.json';
import uk from './locales/uk.json';
import pl from './locales/pl.json';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        pl,
        uk
    }
});

export const app = createApp(App);

app.use(i18n);

app.mount('#app');
